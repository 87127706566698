/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.YProduct {
    &-YProductWrapper {
        display: flex;
        background-color: transparent;

        &_isCartEmpty {
            background-color: $gray-background-color;
        }
    }

    &-PictureWrapper {
        flex-basis: 20%;
    }

    &-Picture {
        height: 77px;
        width: 62px;
    }

    &-Content {
        flex-basis: 80%;
    }

    &-Description {
        white-space: pre-line;
        color: var(--color-black);
        opacity: 0.5;
        margin-block-end: 8px;

        span {
            display: block;
        }
    }
}
