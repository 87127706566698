/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

.mpproductlabel-label {
    position: absolute;
    z-index: 3; // <-- decreased default z-index from 8 to 3, to avoid card hover overlap
}

.mpproductlabel-text-label {
    text-align: center;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%
}


@media screen and (max-width: 1000px) {
    .mpproductlabel-text-label {
        font-size: 90%;
    }
}

@media screen and (max-width: 900px) {
    .mpproductlabel-text-label {
        font-size: 80%;
    }
}

@media screen and (max-width: 768px) {
    .mpproductlabel-text-label {
        font-size: 70%;
    }
}

@media screen and (max-width: 767px) {
    .mpproductlabel-text-label {
        font-size: 100%;
    }
}

@media screen and (max-width: 550px) {
    .mpproductlabel-text-label {
        font-size: 85%;
    }
}

@media screen and (max-width: 500px) {
    .mpproductlabel-text-label {
        font-size: 75%;
    }
}

@media screen and (max-width: 420px) {
    .mpproductlabel-text-label {
        font-size: 60%;
    }
}

@media screen and (max-width: 360px) {
    .mpproductlabel-text-label {
        font-size: 60%;
    }
}

@media screen and (max-width: 320px) {
    .mpproductlabel-text-label {
        font-size: 50%;
    }
}

.ImageZoomPopup .mpproductlabel-label {
    display: none !important;
}

.mp-tooltip {
    display: inline-block;
    position: absolute;
    inset-inline-start: 40%;
    z-index: 100;
    inset-block-start: 105%;
}

.mp-tooltip .mp-tooltiptext {
    word-break: break-word;
    visibility: hidden;
    width: 120px;
    background-color: #EFF2FB;
    color: #1979C3;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    inset-block-start: 20%;
    position: absolute;
    z-index: 1;
    inset-inline-start: 100%;
    margin-inline-start: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

@media only screen and (max-width: 1024px) {
    .mp-tooltip {
        inset-inline-start: 60%;
    }

    .mp-tooltip .mp-tooltiptext {
        width: 70px;
    }
}

.mpproductlabel-label:hover .mp-tooltiptext {
    visibility: visible;
    opacity: 1;
}

