/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGalleryThumbnailImage {
    @include after-mobile {
        display: flex;
        height: auto;
        width: 100%;
        padding: 0;
        background-color: var(--color-white);
    }

    .VideoThumbnail {
        min-height: 95px;
        min-width: 76px;

        @include tablet {
            min-height: 58px;
            min-width: 46px;
            width: 46px;
        }

        &-Button {
            width: 100%;
            height: 100%;
        }

        &-PlayIcon {
            border-left: 24px solid var(--video-play-icon-fill);
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
        }

        img {
            object-fit: fill;
        }
    }
}
