/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGallery {
    @include after-mobile {
        display: flex;
        max-height: 815px;
        min-height: 330px;
        min-width: 0;
        align-self: flex-start;
        margin-block-end: 0;
    }

    @include mobile {
        margin-block-end: 32px;
    }

    &-GalleryTransformWrapper_isImageLoading {
        .ProductGallery-SliderImage_isPdpImage {
            opacity: 1;
        }
    }

    &-Wrapper {
        @include after-mobile {
            height: 100%;
            margin-block-end: 16px;
        }
    }

    .react-transform-component {
        @include after-mobile {
            width: 100%;
        }

        @include mobile {
            max-width: 304px;
            margin-inline-end: 6px;

            &:last-of-type {
                margin-inline-end: 0;
            }
        }
    }

    .ProductGallery-SliderImage {
        @include after-mobile {
            aspect-ratio: 656 / 815;
        }

        img {
            object-fit: cover;
        }
    }

    .Slider-Arrow {
        @include after-mobile {
            display: none;
        }
    }

    &-Slider {
        .Slider-Wrapper {
            @include mobile {
                padding-inline: 0;
            }
        }
    }

    &-DeliveryRestriction {
        position: absolute;
        display: flex;
        align-items: center;
        inset-block-end: 0;
        padding: 2px 8px;
        background-color: $black;
        width: 100%;
        z-index: 1;

        @include mobile {
            max-width: 304px;
        }

        span {
            font-size: 1rem;
            line-height: 1.25rem;
            color: rgba($white, 0.8);
        }
    }
}
