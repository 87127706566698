/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NewProducts {
    width: 90vw;
    margin: auto;

    &-Wrapper {
        position: relative;
        width: auto;
        margin: 52px 60px 120px;

        @include tablet {
            margin-inline: 16px;
        }

        @include medium-tablet {
            margin-inline-end: 0;
        }

        @include mobile {
            margin-inline-start: 16px;
            margin-inline-end: 0;
        }

        ul {
            padding-inline-start: 0;
        }
    }

    &-Slider {
        >.Slider-Wrapper {
            gap: 16px;

            > * {
                @include medium-tablet {
                    width: 304px;
                }
            }
        }

        >.Image,
        >.Image-Wrapper {
            display: block;
            min-height: 272px;
            min-width: 272px;
            width: calc(100vw - 56px);
            height: calc(100vw - 56px);
        }
    }

    &-Products {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 12px;
        padding: 0;
        width: 100%;

        @include mobile {
            display: flex;
            border: 0;
            overflow: hidden;
        }

        @include tablet {
            --new-products-per-page-count: calc(var(--new-products-per-page-count) - 2);

            grid-template-columns: repeat(4, 1fr);
        }

        @include medium-tablet {
            display: block;
        }

        @include narrow-desktop {
            --new-products-per-page-count: 4;
        }

        @include wide-desktop {
            --new-products-per-page-count: 4;
        }

        grid-template-columns: repeat(var(--new-products-per-page-count), 1fr);
    }

    &-Product {
        .ProductCard {
            .Image {
                &-Image {
                    max-height: 100% !important;/* stylelint-disable declaration-no-important */
                }
            }
    
            &-Figure {
                max-height: 100% !important;
                max-width: 100% !important;
                
                @include desktop {
                    width: 100% !important;
                    max-height: 100% !important;
                }
            }
    
            &-FigureReview {
                max-height: 100% !important;
                max-width: 100% !important;
        
                @include desktop {
                    height: 100% !important; 
                    width: 100% !important;
                }
            }
        }

        @include mobile {
            padding-inline-start: 0;
            flex-shrink: 0;
            width: calc(100vw - 56px);
            scroll-snap-align: center;
            transition: transform 0.3s, opacity 0.3s;

            .ProductCard {
                margin-block-end: 0;
                padding-inline-end: 16px;

                &:last-child {
                    padding-inline-end: 0;
                }
            }
        }

        &-Active {
            opacity: 1;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 32px;

        @include mobile {
            padding-inline-end: 16px;
        }

        @include medium-tablet {
            margin-inline-start: 0;
            margin-inline-end: 16px;
        }
    }

    &-Title {
        text-transform: uppercase;
        font-weight: 600;
        line-height: 126%;
        font-size: 0.875rem;
        margin: 0;
    }

    &-Link {
        text-transform: uppercase;
        line-height: 126%;
        font-size: 0.875rem;
        text-decoration: none;
        color: var(--link-color);
        font-weight: 600;

        &:visited {
            color: var(--link-color);
        }

        &:hover {
            color: $black;
        }

        &::before {
            content: '';
            position: absolute;
            inset-block-end: 0;
            inset-inline-end: 0;
            width: 0;
            height: 2px;
            background-color: $black;
            transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        }

        &:hover::before {
            inset-inline: 0 auto;
            width: 100%;
        }
    }

    &-ProgressWrapper {
        padding: 14px;

        @include mobile {
            padding: 0 16px 0 0;
            margin-block-start: 24px;
        }

        @include medium-tablet {
            padding: 0 16px 0 0;
        }
    }
}
