/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductActions {
    > * {
        opacity: 0;
    }

    &_isContentLoaded {
        > * {
            animation: appear-nice 2s cubic-bezier(.19,1,.22,1) forwards;
        }
    }

    &_isAnimationEnded {
        > * {
            animation: none;
            opacity: 1;
        }
    }

    &-AddToCart {
        font-size: 0.875rem;
        line-height: 126%;

        .ProductCard {
            &-PlusIconWrapper {
                display: none;
            }
        }

        &.StyledButton-Button {
            .click {
                transform: translate(-50%, -50%);
            }
        }
    }

    &-Button {
        font-size: 0.875rem;
        line-height: 1.125rem;
        transition: color .3s linear;

        &:hover {
            color: var(--primary-dark-color);
        }
    }

    .Field {
        &_type {
            &_numberWithControls {
                .Field-InputWrapper {
                    svg {
                        height: 1rem;
                        width: 1rem;
                    }
                }
            }
        }
    }

    &-PriceWithReviews {
        @include mobile {
            flex-direction: row;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
            gap: 8px;
        }

        @include after-mobile {
            flex-direction: row;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
            gap: 0;
        }
    }

    &-Reviews {
        @include small-tablet {
            order: 2;
        }

        @include mobile {
            order: 2;
        }
    }

    &-Schema {
        min-width: unset;
    }

    &-PriceWrapper {
        @include after-mobile {
            margin-block-start: 0;
        }
    }

    &-Stock {
        margin-block-start: 0;

        @include after-mobile {
            margin-inline-end: 24px;
        }

        &_isOutOfStock {
            color: $text-light-grey;
            border-color: $text-light-grey;
        }
    }

    &-Section {
        &_type_sku {
            @include mobile {
                margin-block-start: 0;
            }
        }
    }

    &-AddToCartWrapper {
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 8px;

        .Button {
            @include after-mobile {
                width: 100%;
                max-width: 16.25rem;
            }
        }
    }

    &-Qty {
        &.Field {
            @include after-mobile {
                margin-inline-end: 0;
            }
        }
    }

    &-ActionsWrapper {
        @include after-mobile {
            margin-block: 18px;
        }
    }

    &-ShortDescription {
        font-size: var(--paragraph-font-size);
        line-height: 1.25rem;

        p {
            line-height: 1.25rem;
        }

        *:last-child {
            margin-block-end: 0;
        }

        li::before {
            position: absolute;
            inset-inline-start: -17px;
            padding-inline-end: 0;
        }

        ul {
            padding-inline-start: 40px;
        }
    }

    &-StickyBottomSection {
        display: flex;
        width: 100%;

        .AddToCart {
            width: 8.25rem;
            margin: auto 0 auto auto;
            float: inline-end;
        }

        h3 {
            font-size: 1.125rem;
            font-family: $font-Klavika;
            color: var(--primary-base-color);
            font-weight: 600;
            line-height: 1.26rem;
        }

        .Field {
            &-Wrapper {
                margin-block: auto;
                width: 140px;
                padding-inline-start: 32px;
            }
        }
    }

    &-NamePrice {
        padding-inline-start: 15px;
        max-width: 675px;
        inset-inline-start: 0;
        margin-block: auto;
    }

    &-Weight {
        color: $black;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.26rem;
        display: flex;
        flex-direction: row;
    }

    &-Dash {
        margin-inline-start: 12px;
        margin-inline-end: 12px;
    }

    &-PriceAndWeight {
        display: flex;
        align-items: center;
        line-height: 1.26rem;
    }

    &-UniqueSellingPoints {
        margin-block-start: 16px;

        @include after-mobile {
            margin-block-start: 8px;
        }
    }

    &-Title {
        line-height: 2rem;
        font-size: 2rem;

        @include desktop {
            line-height: 2.625rem;
            font-size: 2.625rem;
        }

        @include tablet {
            line-height: 2.25rem;
            font-size: 2.25rem;
        }
    }

    &-QuantityChanger {
        display: flex;

        &_isPlusDisabled {
            .Field {
                &_type {
                    &_numberWithControls {
                        .PlusButton {
                            opacity: 0.25;
                        }
                    }
                }
            }
        }
    }

    &-WeightContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
