/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductListPage {
    padding-inline-start: 16px;
    padding-inline-end: 16px;

    @include small-tablet {
        padding-inline-start: 32px;
        padding-inline-end: 32px;
    }

    @include tablet {
        padding-inline-start: 52px;
        padding-inline-end: 52px;
    }
}

.Category {
    &-PageHeader {
        padding: 0 32px 42px 32px;

        @include narrow-desktop-plus {
            padding: 0 32px 52px 32px;
        }

        @include desktop {
            padding: 0 60px 52px 60px;
        }

        @include mobile {
            padding: 0 16px 52px 16px;
        }
    }
}
