/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductReviews {
    @include mobile {
        scroll-margin-block-start: 150px;
    }

    @include tablet {
        scroll-margin-block-start: 150px;
    }

    &-SummaryWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    &-SummaryDetails {
        @include after-mobile {
            span {
                font-size: 1.125rem;
            }
        }

        p {
            margin-block-end: 0px;
        }
    }

    &-Button {
        width: fit-content;
        text-wrap: nowrap;
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-start: 0px !important;

        @include mobile {
            width: 10.563rem;
            max-width: 10.563rem;
            max-height: 2.875rem;
        }
    }

    &-Summary {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: unset;
        width: 1092px;
        max-width: 100%;
        margin: 32px auto;

        @include desktop {
            padding-inline-start: 212px;
            padding-inline-end: 160px;
            flex-wrap: nowrap;
        }

        @include tablet {
            justify-content: space-between;
        }

        @include mobile {
            flex-wrap: wrap;
            flex-direction: column;
        }
    }

    &-ProductName {
        display: block;
        margin-block: 0 8px;
        font-size: 1rem;
        font-weight: 600;
        max-width: 100%;

        strong {
            font-weight: 600;
        }

        @include desktop {
            font-size: 1.125rem;
        }
    }

    &-RatingWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-inline-end: 32px;
        margin-block-end: 24px;
    }

    .ProductReviews {
        &-Button {
            @include mobile {
                margin-inline-start: 0;
                margin-block-start: 1.5rem;
            }

            @include after-mobile {
                margin-inline-start: 2rem;
                margin-block-start: 0;
                font-size: 0.9rem;
                padding-inline-start: 24px;
                padding-inline-end: 24px;
                /** The following line overrides the "overflow: hidden" rule set in the button style
                /** Because it prevented paddings from being applied correctly
                */
                overflow: visible;
            }
        }
    }
}

.ProductReviewItem {
    &-Date {
        min-width: fit-content;

        @include desktop {
            min-width: 130px;
            text-align: end;
        }
    }
}
