/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPrice {
    line-height: 0;

    &-PriceValue {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.26rem;
    }

    &-UniqueSellingPoints {
        margin-block-start: 16px;

        @include after-mobile {
            margin-block-start: 8px;
        }
    }

    &-AddToCart {
        font-size: 0.875rem;
        line-height: 126%;
    }

    .Field {
        &_type {
            &_numberWithControls {
                .Field-InputWrapper {
                    svg {
                        height: 1rem;
                        width: 1rem;
                    }
                }
            }
        }
    }

    [dir="ltr"] & {
        &-PriceValue,
        &-HighPrice {
            font-size: 1rem;
            line-height: 1.25rem;

            @include after-mobile {
                font-size: 1.125rem;
                line-height: 1.625rem;
                font-weight: 900;
            }

            @include tablet {
                font-size: 1rem;
                line-height: 1.26rem;
            }
        }

        &-HighPrice {
            @include mobile {
                margin-block-start: 0;
            }
            
            @include after-mobile {
                font-weight: 500;
            }
        }
    }
}
