/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductList {
    &-Message {
        font-size: 0.875rem;
        line-height: 126%;
    }

    &-LoadMoreText {
        font-size: 0.875rem;
        line-height: 126%;
    }

    &-Product {
        @include medium-tablet {
            width: 304px;
        }
    }

    &-SliderPage {
        display: flex;
        flex-direction: row;
        height: min-content;

        .ProductCard {
            .Image {
                &-Image {
                    max-height: 100% !important;/* stylelint-disable declaration-no-important */
                }
            }
    
            &-Figure {
                max-height: 100% !important;
                max-width: 100% !important;
                
                @include desktop {
                    width: 100% !important;
                    max-height: 100% !important;
                }
            }
    
            &-FigureReview {
                max-height: 100% !important;
                max-width: 100% !important;
        
                @include desktop {
                    height: 100% !important; 
                    width: 100% !important;
                }
            }
        }

        .ProductList-Product {
            max-width: calc((100% - 48px) / 4);
            padding-inline-end: 0;
            margin-inline-end: 16px;

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }
}
