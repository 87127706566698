/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.YProductsSlider {
    &-SliderWrapper {
        &_IsDisabled {
            cursor: default;
        }

        .RulesProgressBar {
            &-Wrapper {
                margin-block-start: 12px;
            }
        }
    }

    &-Slider {
        .Slider-Crumbs {
            position: relative;
            grid-gap: 0px;
            width: 80%;
            margin-inline-start: auto;
            margin-inline-end: 0;
            grid-template-columns: repeat(auto-fit, 12px);
            inset-block-end: initial;
            margin-block-start: 16px;
        }

        .Slider-Crumb {
            width: 4px;
            height: 4px;

            &_isActive {
                width: 6px;
                height: 6px;
            }
        }

        &_IsApplied {
            padding-block-end: 30px;
        }

        .AddToBag {
            &-Button {
                inset-block-start: initial;
                margin-block-start: 12px;
            }
        }

        .YProduct {
            &-Title {
                margin-block-end: initial;
            }
        }
    }
}
