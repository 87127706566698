/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

$total-padding-x-mobile: 32px;
$mobile-gap: 8px;
$small-tablet-gap: 16px;
$medium-tablet-gap: 20px;
$sidebar-width-tablet: 324px;
$total-padding-x-tablet: 104px;
$tablet-gap: 34px;
$after-tablet-gap: 44px;
$sidebar-width-desktop: 341px;
$total-padding-x-desktop: 120px;
$desktop-gap: 66px;

.ProductCard {
    opacity: 0;
    animation: appear-nice 1.25s cubic-bezier(.19,1,.22,1) forwards;

    @include mobile {
        margin-block-end: 32px;
    }

    @include tablet {
        margin-block-end: 42px;
    }

    &_isAnimationEnded {
        animation: none;
        opacity: 1;
    }

    &_isLoading {
        animation: appear 1s 1s ease-in-out forwards;
        opacity: 0;
        transform: translate3d(0,30px,0);
    }

    &_shouldHideLabelsOnHover {
        &:hover {
            .mpproductlabel-label {
                display: none;
            }

            .ProductCard {
                &-OutOfStockWrapper {
                    @include desktop {
                        display: none;
                        opacity: 0;
                    }
                }
            }
        }
    }

    &:hover {
        .ProductCard {
            &-OutOfStockWrapper {
                @include desktop {
                    display: flex;
                    opacity: 1;
                }
            }

            &-FigureReview {
                &_isHovered {
                    opacity: 1;
                }
            }
        }

        @include before-desktop {
            .ProductLabels {
                visibility: visible !important; /* stylelint-disable declaration-no-important */
            }
        }
    }

    &:focus {
        .ProductCard {
            &-OutOfStockWrapper {
                @include after-mobile {
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }

    &-Description {
        position: absolute;
        padding: 14px 14px 0 14px;
        inset-inline-start: 0;
        inset-block-start: 0;
        opacity: 0;
        background-color: var(--product-card-bg);
        transition: opacity ease-out 0.25s;
        height: unset;
        width: 100%;
    }

    &-DescriptionText {
        height: 100%;
    }

    &-Figure {
        max-height: 438px;
        
        @include desktop {
            width: 100%;
            max-height: 438px;
        }
    }

    &-FigureReview {
        transition: opacity ease-out 0.25s;
        opacity: 1;
        max-height: 438px;

        @include desktop {
            height: auto;
            width: 100%;
        }

        .image {
            &_image {
                transform: scale(1.15);
            }
        }

        &_isHovered {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: var(--product-card-bg);

            @include tablet {
                display: none;
            }

            @include mobile {
                display: none;
            }

            .ProductCard {
                &-Description {
                    position: relative;
                    padding: 14px 14px 0 14px;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    background-color: var(--product-card-bg);
                    height: unset;
                    width: 100%;
                    transition: opacity ease-out 0.25s;
                    opacity: 0;

                    @include tablet {
                        display: none;
                    }
                }

                &-ImagesWrapper {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    justify-content: center;

                    @include desktop {
                        padding-inline: 16px;
                        padding-block-end: 12px;
                    }

                    .Image {
                        padding-block-end: 0;
                        display: block;

                        @include tablet {
                            padding-block-end: 120%;
                        }

                        &-Image {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                &-Figure {
                    display: flex;
                    flex-direction: column;
                }

                &-AddToCartWrapper {
                    position: relative;
                    inset-block-end: 0;
                    padding-block-end: 16px;
                }
            }
        }
    }

    &-ImagesWrapper {
        height: 100%;
    }

    &-AddToCardAndIconWrapper {
        position: relative;
        margin-block-start: 12px;

        @include desktop {
            display: none;
        }

        @include tablet {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--button-background);
        }

        @include mobile {
            margin-block-start: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--button-background);
        }
    }

    &-AddToCart {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        gap: 12px;
        height: 32px;

        @include mobile {
            &.AddToCart {
                // width MUST be 100% otherwise the active button area does not cover it's container
                width: 100%;
                border-radius: 0;
                min-width: unset;
                font-size: 0.875rem;
            }
        }

        @include tablet {
            &.AddToCart {
                // width MUST be 100% otherwise the active button area does not cover it's container
                width: 100%;
                border-radius: 0;
                min-width: unset;
                font-size: 0.875rem;
            }
        }

        @include desktop {
            justify-content: unset;
        }

        .ProductCard-PlusIconWrapper {
            @include desktop {
                display: none;
            }

            @include small-tablet {
                display: flex;

                svg {
                    stroke: var(--color-white);
                }
            }
        }

        span {
            width: auto;
            letter-spacing: 0.08em;

            @include desktop {
                margin-inline-start: unset;
            }

            @include small-tablet {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-start: 0;
            }

            @include mobile {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-inline-start: 0;
            }
        }
    }

    &-PlusIconWrapper {
        position: relative;

        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include tablet {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .PlusIcon {
            @include mobile {
                stroke: var(--color-white);
            }

            @include tablet {
                stroke: var(--color-white);
            }
        }
    }

    &-Content {
        background-color: transparent;

        .ProductCard-IconsWrapper {
            .ProductCard-IconWrapper {
                .Image {
                    &-Image {
                        height: 100%;
                        object-fit: contain;
                    }

                    &-HeightFull {
                        height: 100%;
                    }
                }
            }
        }

        @media only screen and (max-width: 810px) {
            justify-content: flex-end;
        }
    }

    .Image {
        display: flex;
        padding-block-end: 120%;

        @include tablet {
            padding-block-end: 120%;
        }

        @include mobile {
            padding-block-end: 125%;
        }

        &-Image {
            width: 100%;
            max-height: 438px;
            object-fit: cover;
        }
    }

    &-VimeoWrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &-VimeoVideo {
        position: relative;
        padding-block-end: 121.6%;
        height: 0;
    }

    &-Picture {
        max-height: 438px;

        &_isTexture {
            .Image {
                &-Image {
                    height: 100%;
                }
            }
        }
    }

    &-IconsWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        inset-inline-start: 0;
        margin-block: 16px;
        z-index: 1;
        height: 32px;
        gap: 16px;

        @include desktop {
            display: none;
        }
    }

    &-IconDescriptionWrapper {
        width: 161px;
        position: absolute;
        inset-block-end: 20px;

        @include mobile {
            width: calc((50vw - #{$mobile-gap} - #{$total-padding-x-mobile / 2}));
        } 

        @include small-tablet {
            width: calc((100vw - #{$small-tablet-gap} - #{$total-padding-x-mobile}) / 3 );
        }

        @include tablet-portrait {
            width: calc((100vw - #{$tablet-gap} - #{$sidebar-width-tablet} - #{$total-padding-x-tablet}) / 2 );
        }

        @include medium-tablet-only {
            width: calc((100vw - #{$medium-tablet-gap} - #{$total-padding-x-tablet}) / 3 );
        }

        @include narrow-desktop-plus {
            width: calc((100vw - #{$after-tablet-gap} - #{$sidebar-width-tablet} - #{$total-padding-x-tablet}) / 3 );
        }

        @include desktop {
            width: calc((100vw - #{$desktop-gap} - #{$sidebar-width-desktop} - #{$total-padding-x-desktop}) / 3 );
        }

        &_isLastStyle {
            margin-inline-start: -96px;
        }

        &_isLastAndSecond {
            margin-inline-start: -48px;
        }
    }

    &-IconDescription {
        font-size: 14px;
        word-wrap: break-word;
        display: inline-block;
        background-color: var(--color-black);
        color: var(--color-white);
        padding: 0.1rem 0.2rem;
    }

    &-IconWrapper {
        margin: 0.2rem;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-AddedValueHeader {
        margin-block-end: 0px;
        font-size: 1rem;
        font-weight: 400;
    }

    &-AddedValue {
        margin: 4px 0;
        padding: 4px 0;
        opacity: 0.5;
    }

    &-AddedValues:not(:last-child) {
        border-bottom: 1px solid rgba(34, 34, 34, 0.15);
    }

    &-Name {
        display: inline-block;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60ch;
        height: 3rem;
        line-height: 126%;

        @include tablet {
            margin-block-start: 0;
        }

        @include mobile {
            font-size: 1rem;
            line-height: 1.26rem;
            margin-block-start: 0;
            margin-block-end: unset;
            padding-block-end: unset;
            height: 40px;
        }

        @include desktop {
            height: unset;
        }
    }

    &-PriceWrapper {
        @include mobile {
            margin-block-start: 8px;
            margin-block-end: 12px;
        }
    }

    &-OutOfStock {
        height: 32px;
        margin-block-start: 12px;

        @include desktop {
            display: none;
        }

        @include mobile {
            margin-block-start: 0;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-family: var(--current-font-family);
            letter-spacing: 0.08em;
            font-size: 0.875rem;
            line-height: 1.125rem;
            font-weight: 600;
            background-color: $default-primary-base-color;
            text-transform: uppercase;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            padding: 1px 7px;
        }
    }

    .ProductPrice {
        &-PriceValue,
        &-HighPrice {
            font-weight: unset;
        }
    }

    &-OutOfStockWrapper {
        cursor: pointer;
        display: none;
        position: relative;
        align-items: center;
        justify-content: center;
        transition: opacity ease-out 0.25s;
        opacity: 0;
        margin-block-end: 16px;
        padding-block-start: 12px;

        p {
            color: $text-light-grey;
            font-size: 1rem;
            line-height: 1.25rem;
            font-weight: 400;
            border: 1px solid $text-light-grey;
            margin: 0;
            padding: 1px 7px;
        }
    }

    &-DeliveryRestriction {
        position: absolute;
        display: flex;
        align-items: center;
        inset-block-end: 0;
        padding: 2px 8px;
        background-color: $black;
        width: 100%;

        span {
            font-size: 1rem;
            line-height: 1.25rem;
            color: rgba($white, 0.8);
        }
    }

    a {
        text-decoration: none;
    }
}

iframe {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border: none;
}
