/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    @include after-mobile {
        grid-column: 1 / span 2;
        margin-block-start: 46px;
    }

    &-Wrapper {
        @include after-mobile {
            padding-inline: 60px;
        }
    }

    &-List {
        @include after-mobile {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            justify-content: center;
        }

        @include small-tablet {
            display: block;
        }

        @include mobile {
            display: block;
            width: auto;
        }
    }

    &-CardWrapper {
        min-width: 0;
    }

    .ProductCard {
        @include after-mobile {
            margin-block-end: 0;
        }

        &-PriceWrapper {
            @include after-mobile {
                margin-block-end: 0;
            }
        }
    }

    .Slider {
        .ProductCard {
            max-width: 304px;
        }

        .ProductLinks-CardWrapper {
            width: 90%;
            max-width: 304px;

            .ProductCard {
                width: 100%;
            }
        }
    }
}
