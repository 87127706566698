/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.ProductSlider {
    .ChevronIcon {
        &Wrapper {
            height: 35px;
            width: 35px;
            position: absolute;
            z-index: 5;
            inset-block-start: 50%;
            transform: translateY(-50%);
        }

        height: 100%;
        width: 100%;
    }


    .ProductSlider-Crumbs {
        height: auto;
        min-height: 16px;
        position: relative ;
        margin-block-start: 15px;
        display: grid !important;
        grid-gap: 4px ;
        width: 100%;
        grid-template-columns: repeat(auto-fit, 16px);
        justify-content: center;
        inset-block-end: 12px;
        align-items: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            margin-block-end: 0;

            &::before {
                display: none;
            }


            &.slick-active {
                .ProductSlider-Crumb {
                    width: 16px;
                    height: 16px;
                }
            }

            .ProductSlider-Crumb {
                flex-shrink: 0;
                transition: width 300ms, height 300ms;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--primary-base-color) ;
            }
        }
    }


    .slick {
        &-slider {
            overflow-x: hidden;
        }

        &-list {
            overflow: initial;
        }

        &-prev {
            inset-inline-start: 0;
        }

        &-next {
            inset-inline-end: 0;
        }

        &-disabled {
            opacity: .5;
        }
    }

    .Carousel {
        .ProductCard {
            pointer-events: none;
        }
    }

    .ProductCard {
        @include mobile {
            padding: 0 20px;
        }

        &-VisibleOnHover {
            inset-block: auto 0;
            z-index: 1 !important;
            box-shadow: none !important;

            div {
                background-color: var(--color-white);
            }
        }
    }
}
