/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductReviewRating {
    align-items: center;
    gap: 8px;
    margin-inline-end: 0;
    cursor: pointer;
    $transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);

    &-Counter {
        font-size: 1rem;
        letter-spacing: 0;
        font-weight: 400;
        line-height: 20px;
        color: var(--primary-base-color);
        position: relative;
        transition: $transition;
    }

    .StarIcon path {
        transition: $transition;
    }

    &:hover {
        .ProductReviewRating-Counter::before {
            inset-inline-start: 0;
            inset-inline-end: auto;
            width: 100%;
        }

        .ProductReviewRating-Counter {
            color: var(--primary-dark-color);
        }
    }

    &-Counter::before {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary-dark-color);
        transition: $transition;
    }
}
